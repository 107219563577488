/* Custom Papercup Classes*/
/* Leave some naming padding (i.e. -1, -2, -4, -5) for more or less shadow */
.shadow-img-3 {
  box-shadow: 0 -1px 5px 0 rgba(0,0,0,0.30);
}

.visibility-hidden { visibility: hidden;}
.visibility { visibility: visible;}
.pointer-none { pointer-events: none; }

/* Need this for animations */
.before-z-negative::before { z-index: -1;}
.before-z-0::before { z-index: 0;}
.before-z-1::before { z-index: 1;}
.before-z-2::before { z-index: 2;}
.before-z-3::before { z-index: 3;}
.before-z-4::before { z-index: 4;}
.before-z-5::before { z-index: 5;}

/* In the future, could be done with  a post processing plugin */
.pff {
  font-family: "Helvetica Now", -apple-system, ".SFNSText-Regular", "San Francisco", Roboto, "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
}

/* We have varied line height, rather than a ratio, custom classes*/
.lh-f1 { line-height: 44px; }
.lh-f2 { line-height: 34px; }
.lh-f3 { line-height: 22px; }
.lh-f4 { line-height: 20px; }
.lh-f5 { line-height: 16px; }
.lh-f6 { line-height: 14px; }

.h6 { height: var(--width-6);}
.h8 { height: var(--width-7);}
/*.h8 { height: var(--width-8);}*/
/*.h9 { height: var(--width-9);}*/
.w6 { width: var(--height-6);}
.w7 { width: var(--height-7);}
/*.w8 { width: var(--width-8);}*/
/*.w9 { width: var(--width-9);}*/
.vw-100 { width: 100vw;}
.min-w-100 { min-width: 100%;}


.mt-auto { margin-top: auto }
.mb-auto { margin-bottom: auto }

.c-primary { color: var(--main-contrast); fill: var(--main-contrast); }
.c-contrast { color: var(--brand-contrast); fill: var(--brand-contrast); }
.c-secondary { color: var(--grey); fill: var(--grey); }
.c-ternary { color: var(--background-highlight); fill: var(--background-highlight); }
.c-invert { color: var(--dark-low); fill: var(--dark-low); }
.c-brand { color: var(--orange); fill: var(--orange); }
.c-error { color: var(--red); fill: var(--red); }
.c-warning { color: var(--yellow); fill: var(--yellow); }
.c-success { color: var(--green); fill: var(--green); }

.bg-primary { background-color: var(--background-main); }
.bg-primary-80  { background-color: var(--background-lowlight); }
.bg-secondary { background-color: var(--background-secondary); }
.bg-ternary { background-color: var(--background-highlight); }
.bg-invert { background-color: var(--white-ghost); }
.bg-brand { background-color: var(--orange); }
.bg-brand-10 { background-color: var(--orange-10); }
.bg-error { background-color: var(--red); }
.bg-warning { background-color: var(--yellow); }
.bg-success { background-color: var(--green); }
.bg-error-10 {  background-color: var(--red-10);}
.bg-warning-10 {  background-color: var(--yellow-10);}
.bg-success-10 {  background-color: var(--green-10);}
.bg-default-10 {  background-color: var(--grey-10);}

.bg-splash { background-image: radial-gradient(circle at 2% 6%, var(--red), var(--orange) 25%, var(--orange) 67%, var(--yellow)); }

.hover-bg-primary:hover { background-color: var(--grey-5); }
/*.hover-bg-primary-80 :hover { background-color: var(--background-lowlight); }*/
/*.hover-bg-secondary:hover { background-color: var(--dark-mid); }*/
/*.hover-bg-ternary:hover { background-color: var(--background-highlight); }*/
/*.hover-bg-invert:hover { background-color: var(--white-ghost); }*/
/*.hover-bg-brand:hover { background-color: var(--orange); }*/
/*.hover-bg-error:hover { background-color: var(--red); }*/
/*.hover-bg-warning:hover { background-color: var(--yellow); }*/
/*.hover-bg-success:hover { background-color: var(--green); }*/
/*.hover-bg-error-10:hover {  background-color: var(--red-10);}*/
/*.hover-bg-warning-10:hover {  background-color: var(--yellow-10);}*/
/*.hover-bg-success-10:hover {  background-color: var(--green-10);}*/
/*.hover-bg-default-10:hover {  background-color: var(--gray-10);}*/

.b--primary { border-color: var(--dark-low); }
.b--secondary { border-color: var(--dark-mid); }
.b--ternary { border-color: var(--background-highlight); }
.b--contrast { border-color: var(--brand-contrast); }
.b--gray { border-color: var(--grey); }
.b--gray-20 { border-color: var(--grey-20); }
.b--gray-7 { border-color: var(--grey-5); }
.b--brand { border-color: var(--orange); }
.b--brand-20 { border-color: var(--orange-20); }
.b--error { border-color: var(--red); }
.b--error-10 { border-color: var(--red-10); }
.b--warning { border-color: var(--yellow); }
.b--warning-10 { border-color: var(--yellow-10); }
.b--success { border-color: var(--green); }
.b--success-10 { border-color: var(--green-10); }

.inner-shadow-l { background: linear-gradient(to right, var(--background-main) 0%,rgba(0,0,0,0) 100%)}
.inner-shadow-r { background: linear-gradient(to left, var(--background-main) 0%,rgba(0,0,0,0) 100%)}
.inner-shadow-t { background: linear-gradient(to bottom, var(--background-main) 0%,rgba(0,0,0,0) 100%)}
.inner-shadow-b { background: linear-gradient(to top, var(--background-main) 0%,rgba(0,0,0,0) 100%)}

.inner-panel-shadow-l { background: linear-gradient(to right, var(--background-secondary) 0%,rgba(0,0,0,0) 100%)}
.inner-panel-shadow-r { background: linear-gradient(to left, var(--background-secondary) 0%,rgba(0,0,0,0) 100%)}
.inner-panel-shadow-t { background: linear-gradient(to bottom, var(--background-secondary) 0%,rgba(0,0,0,0) 100%)}
.inner-panel-shadow-b { background: linear-gradient(to top, var(--background-secondary) 0%,rgba(0,0,0,0) 100%)}

.input-shadow { box-shadow: 0 2px 5px 0 var(--black-40) }

.mhn3-ns { margin-left: -30px; margin-right: -30px }

.indicator-button {border-radius: 2px; box-shadow: 0 2px 5px 0 var(--black-40); border: solid 1px rgba(152, 150, 147, 0.1); background-color: var(--background-highlight); padding: 3px 7px 3px 5px;}

.overlapping-svg { top: -58px; left: 20px; margin-bottom: -38px; position: relative; }

.video-border { border: 2px solid white; border-radius: 2px; }

.video-overlay { position: absolute; width: 100%; height: 93%; background: #0000007a; top: 2px; left: 2px; text-align: center; line-height: 120px; }

.dark-striped-background {     background: repeating-linear-gradient(
  115deg,
  var(--grey-5),
  var(--grey-5) 5px,
  var(--grey-20) 5px,
  var(--grey-20) 8px
);}

.dropdown-options {
  width: 500px;
  margin: 5px 0;
}
